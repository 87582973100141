import React, { useState, useEffect } from 'react'
import { getFirebaseDocs, getUrlParams } from '../common'
import { addHours, format } from 'date-fns'
import { useDispatch, useSelector } from 'react-redux'
import Logo from '../assets/Logo.png';

import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore'
import { db } from '../firebase-config'
import SortIcon from '../assets/sort.png'
import { setActions, setCategories, setStaff, setVenues } from '../redux/userReducer';
import { hoursToAdd, types } from '../constants';

const ViewDetailed = () => {


    const [incidents, setIncidents] = useState([])

    const venues = useSelector((state) => state.user.venues)
    const categories = useSelector((state) => state.user.categories)
    // const actions = useSelector((state) => state.user.actions)
    const staff = useSelector((state) => state.user.staff)
    const dispatch = useDispatch()

    const [orgInfo, setOrgInfo] = useState()

    useEffect(() => {
        const params = getUrlParams()
        if (params && params.orgId && params.id) {
            const orgId = params.orgId
            fetchOrg(orgId)
            fetchVenues(orgId)
            fetchCategories(orgId)
            fetchActions(orgId)
            fetchStaff(orgId)
            fetchIncidents(orgId, params.id)
        }
    }, [])



    const fetchOrg = async (orgId) => {
        const record = await (await getDoc(doc(db, 'organisations', orgId),)).data()
        setOrgInfo({ ...record, id: orgId })
    }

    const fetchVenues = async (orgId) => {
        const records = await getFirebaseDocs(await getDocs(query(collection(db, 'venues'), where('orgId', '==', orgId))))
        dispatch(setVenues(records))
    }

    const fetchCategories = async (orgId) => {
        const records = await getFirebaseDocs(await getDocs(query(collection(db, 'categories'), where('orgId', '==', orgId))))
        dispatch(setCategories(records))
    }

    const fetchActions = async (orgId) => {
        const records = await getFirebaseDocs(await getDocs(query(collection(db, 'actions'), where('orgId', '==', orgId))))
        dispatch(setActions(records))
    }

    const fetchStaff = async (orgId) => {
        const records = await getFirebaseDocs(await getDocs(query(collection(db, 'users'), where('organisations', 'array-contains-any', [{ id: orgId, role: 'admin' }, { id: orgId, role: 'user' }]))))
        dispatch(setStaff(records))
    }


    const fetchIncidents = async (orgId, id) => {
        const records = await getFirebaseDocs(await getDocs(query(collection(db, 'incidents'), where('orgId', '==', orgId))))
        records.sort((a, b) => a.created > b.created ? -1 : 1)
        const summaryData = await (await getDoc(doc(db, 'summary', id))).data()
        if (summaryData) {
            setIncidents(records.filter(r => summaryData.records.includes(r.id)))
        }
    }

    const getLabel = (value, array, isArray, isUser) => {
        if (!value) {
            return '-'
        }

        if (isArray) {
            let values = []
            for (let v of value) {
                const matchingObj = array.find(r => r.id === v)
                if (matchingObj) {
                    values.push(matchingObj.name)
                }
            }
            return values.join(', ')
        }
        const matchingObj = array.find(r => r.id === value)
        if (matchingObj) {
            return isUser ? matchingObj.firstName + ' ' + matchingObj.lastName : matchingObj.name
        }
    }

    const getStatusColor = (status) => {
        if (status === 'Submitted') {
            return 'orange'
        } else if (status === 'Approved') {
            return 'green'
        }
    }

    const filteredIncidents = incidents


    const Block = (previewIncident, index) => <div className={index === 0 ? '' : 'page-break'}>
        <div className="header" style={{ borderBottom: '1px solid #d3d3d3', paddingBottom: '10px', display: 'flex', alignItems: 'center', width: 'calc(100%)', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <label>Powered By </label>
                <img src={Logo} style={{ width: '120px', height: '80px', marginLeft: '20px', objectFit: 'contain' }} />
            </div>
            <img src={orgInfo ? orgInfo.image : Logo} style={{ width: '120px', height: '80px', marginLeft: '20px', objectFit: 'contain' }} />
        </div>
        <div className='detailedPreview'>

            <h2>{previewIncident.type} Report: {previewIncident.reportId || ''}</h2>
            {/* <h3>Summary</h3> */}
            <>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ width: '50%' }}>
                        <label style={{ fontWeight: 'bold' }}>Incident Date & Time:</label>
                        <label>{previewIncident && format(addHours(new Date(previewIncident.incidentDate), hoursToAdd), 'dd/MMM/yyyy, HH:mm')}</label>
                    </div>

                    <div style={{ width: '50%' }}>
                        <label style={{ fontWeight: 'bold' }}>Venue:</label>
                        <label> {getLabel(previewIncident.venue, venues)}</label>
                    </div>
                </div>


                <div style={{ width: '50%' }}>
                    <label>Summary:</label>
                    <label>{previewIncident.description}</label>
                </div>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label>Attachment:</label>
                    {previewIncident.attachment ? <img src={previewIncident.attachment.url} style={{ marginLeft: '10px', width: '200px', height: '200px' }} /> : <label>-</label>}
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ width: '50%' }}>
                        <label style={{ fontWeight: 'bold' }}>Report Date & Time:</label>
                        <label> {previewIncident && format(addHours(new Date(previewIncident.created), hoursToAdd), 'dd/MMM/yyyy, HH:mm')}</label>
                    </div>

                    <div style={{ width: '50%' }}>
                        <label style={{ fontWeight: 'bold' }}>Staff:</label>
                        <label> {getLabel(previewIncident.userId, staff, false, true)}</label>
                    </div>
                </div>

                <div>
                    <label>Incident Category:</label>
                    <label>{getLabel(previewIncident.category, categories, true)}</label>
                </div>

                <div>
                    <label>Quantity:</label>
                    <label>{previewIncident.quantity}</label>
                </div>

                <div>
                    <label>Status:</label>
                    <label>{previewIncident.status}</label>
                </div>
                {previewIncident.type === types[1] && <>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ width: '50%' }}>
                            <label style={{ fontWeight: 'bold' }}>Police Attendance:</label>
                            <label> {previewIncident.policeAttendance}</label>
                        </div>
                        {previewIncident.policeAttendance === 'Yes' && <div style={{ width: '50%' }}>
                            <label>Police Name:</label>
                            <label> {previewIncident.policeName}</label>
                        </div>}
                    </div>


                    <div style={{ paddingTop: '10px', borderTop: '1px solid #DCDCDC' }}>
                        <label>Witness Details</label>
                    </div>

                    {
                        previewIncident.witnesses.map((witness, index) => <>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ fontWeight: 'bold' }}> Name:</label>
                                    <label>{witness.name || '-'}</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <label style={{ fontWeight: 'bold' }}> Age:</label>
                                    <label>{witness.age || '-'}</label>
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ fontWeight: 'bold' }}> Gender:</label>
                                    <label>{witness.gender ? witness.gender.label : '-'}</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <label style={{ fontWeight: 'bold' }}> License:</label>
                                    <label>{witness.license || '-'}</label>
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ fontWeight: 'bold' }}> Email:</label>
                                    <label>{witness.email || '-'}</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <label style={{ fontWeight: 'bold' }}> Phone:</label>
                                    <label>{witness.phone || '-'}</label>
                                </div>
                            </div>
                            <div>
                                <label> Other Information:</label>
                                <label>{witness.other}</label>
                            </div>
                        </>)
                    }



                    <div style={{ paddingTop: '10px', borderTop: '1px solid #DCDCDC' }}>
                        <label>Person of Interest Details</label>
                    </div>

                    {
                        previewIncident.interests.map((interest, index) => <>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ fontWeight: 'bold' }}> Name:</label>
                                    <label>{interest.name || '-'}</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <label style={{ fontWeight: 'bold' }}> Age:</label>
                                    <label>{interest.age || '-'}</label>
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ fontWeight: 'bold' }}> Gender:</label>
                                    <label>{interest.gender ? interest.gender.label : '-'}</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <label style={{ fontWeight: 'bold' }}> License:</label>
                                    <label>{interest.license || '-'}</label>
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ fontWeight: 'bold' }}> Email:</label>
                                    <label>{interest.email || '-'}</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <label style={{ fontWeight: 'bold' }}> Phone:</label>
                                    <label>{interest.phone || '-'}</label>
                                </div>
                            </div>
                            <div>
                                <label> Other Information:</label>
                                <label>{interest.other}</label>
                            </div>
                        </>)
                    }

                </>}

            </>

            <h3>History</h3>

            <>
                {previewIncident && previewIncident.events.map((event, index) =>
                    <div key={`event-${index}`} style={{ display: 'flex', alignItems: 'start', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <label style={{ fontWeight: 'bold' }}>{event.message.split(' by ')[1]}</label>
                            <label style={{ color: 'orange' }}>Incident {event.message.split(' by ')[0]}</label>
                        </div>
                        <label>{format(addHours(new Date(event.date), hoursToAdd), 'dd/MMM/yyyy HH:mm')}</label>
                    </div>)}

            </>
        </div>
        {/* <div style={{ display: 'flex', marginTop: 'auto' }}>General Log Report - Copyright Precinct Comply Pty Ltd Reserved</div> */}
    </div>

    return (<div>

        <div style={{ margin: '20px' }}>
            {filteredIncidents.map((row, index) => Block(row, index))}
        </div >
    </div >)
}

export default ViewDetailed