import React, { useState, useRef, useEffect } from 'react'

import { getFirebaseDocs, getUrlParams, randomString, reverseDateString } from '../common'

import { useDispatch, useSelector } from 'react-redux'
import Header from "../components/Header";
import { addDoc, collection, doc, getCountFromServer, getDoc, getDocs, query, updateDoc, where } from 'firebase/firestore'
import { db } from '../firebase-config'
import { setUser } from '../redux/userReducer'

import Chart from 'react-apexcharts'
import { differenceInDays, startOfDay } from 'date-fns'
import { startDateOptions, types } from '../constants';
import Dropdown from '../components/Dropdown';
import Calendar from '../components/Calendar';

import { format, addHours, subDays } from 'date-fns'

let intervalId = ''

const Dashboard = () => {

    const [page, setPage] = useState(0)
    const [errors, setErrors] = useState([])
    const [errorMessage, setErrorMessage] = useState('')
    const [incidents, setIncidents] = useState([])
    const [incidentsGeneral, setIncidentsGeneral] = useState([])

    const [incidentsPrev, setIncidentsPrev] = useState([])
    const [incidentsCurrent, setIncidentsCurrent] = useState([])

    const [incidentsPrev2, setIncidentsPrev2] = useState([])
    const [incidentsCurrent2, setIncidentsCurrent2] = useState([])

    const [incidentsPrev3, setIncidentsPrev3] = useState([])
    const [incidentsCurrent3, setIncidentsCurrent3] = useState([])

    const [startTime, setStartTime] = useState(startOfDay(new Date()))
    const [endTime, setEndTime] = useState(new Date())

    const [compareDate, setCompareDate] = useState(startOfDay(new Date()))

    const [chosenVenues, setChosenVenues] = useState({ label: 'All', value: 'All' })
    const [messages, setMessages] = useState([])
    const [updatingFromLocal, setUpdatingFromLocal] = useState(true)

    const [startReportHour, setStartReportHour] = useState({ label: '00:00', value: '00:00' })
    const [endReportHour, setEndReportHour] = useState({ label: format(new Date(), 'HH:mm'), value: format(new Date(), 'HH:mm') })

    const userInfo = useSelector((state) => state.user.user)
    const venues = useSelector((state) => state.user.venues)
    const categories = useSelector((state) => state.user.categories)
    // const actions = useSelector((state) => state.user.actions)
    const staff = useSelector((state) => state.user.staff)
    const activeOrg = useSelector((state) => state.user.activeOrg)
    const activeOrgInfo = useSelector((state) => state.user.activeOrgInfo)

    const hours = ['01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '24:00']

    const [timelineCategory, setTimelineCategory] = useState('')
    const [timelineCategory2, setTimelineCategory2] = useState('')
    const [timelineCategory3, setTimelineCategory3] = useState('')

    useEffect(() => {
        if (venues && !updatingFromLocal) {

        }
    }, [venues])

    useEffect(() => {
        intervalId = setInterval(() => {
            // fetchPageInfo()
            if (window.screen.width > 400) {
                window.location.reload()
            }
        }, 30000)

        return () => {
            if (intervalId) {
                clearInterval(intervalId)
            }
            intervalId = ''
        }
    }, [])

    useEffect(() => {
        fetchPageInfo()
    }, [activeOrg, categories, startTime, endTime, chosenVenues, venues, compareDate, timelineCategory, timelineCategory2, timelineCategory3])


    const fetchPageInfo = () => {
        if (activeOrg) {
            // Saving time as autorefreshing could happen
            window.localStorage.setItem('precinct-time', new Date().toISOString())
            fetchIncidents(activeOrg.id)
            fetchPreviousCurrentDayIncidents(activeOrg.id)
            fetchStaticMessages()
            if (updatingFromLocal) {
                updateFromLocal()
            }
        }
    }

    useEffect(() => {
        if (activeOrg && chosenVenues && !updatingFromLocal) {
            saveToLocal()
        }
    }, [activeOrg, startReportHour, endReportHour, chosenVenues, compareDate, timelineCategory, timelineCategory2, timelineCategory3])

    const updateFromLocal = () => {
        const val = window.localStorage.getItem(`precinct-${activeOrg.id}-config`)
        if (val) {
            const valueJson = JSON.parse(val)
            if (valueJson.startReportHour) {
                setStartReportHour(valueJson.startReportHour)
            }
            if (valueJson.endReportHour) {
                setEndReportHour(valueJson.endReportHour)
            }

            if (valueJson.chosenVenues) {
                setChosenVenues(valueJson.chosenVenues)
            }
            if (valueJson.compareDate) {
                setCompareDate(valueJson.compareDate)
            }
            if (valueJson.timelineCategory) {
                setTimelineCategory(valueJson.timelineCategory)
            }
            if (valueJson.timelineCategory2) {
                setTimelineCategory2(valueJson.timelineCategory2)
            }
            if (valueJson.timelineCategory3) {
                setTimelineCategory3(valueJson.timelineCategory3)
            }
        }
        setUpdatingFromLocal(false)
    }

    const saveToLocal = () => {
        window.localStorage.setItem(`precinct-${activeOrg.id}-config`, JSON.stringify({ startReportHour, endReportHour, chosenVenues, timelineCategory, timelineCategory2, timelineCategory3, compareDate }))
    }

    const fetchStaticMessages = async () => {
        const records = await getFirebaseDocs(await getDocs(query(collection(db, 'static'), where('orgId', '==', activeOrg.id))))
        setMessages(records)
    }

    useEffect(() => {
        if (startReportHour && endReportHour) {
            const additionalStartHours = parseInt(startReportHour.label.split(':')[0])
            const additionalEndHours = parseInt(endReportHour.label.split(':')[0])
            setStartTime(addHours(startOfDay(new Date()), additionalStartHours))
            setEndTime(addHours(startOfDay(new Date()), additionalEndHours))
        }
    }, [startReportHour, endReportHour])

    useEffect(() => {
        if (activeOrg) {
            fetchPreviousCurrentDayIncidents(activeOrg.id)
        }
    }, [activeOrg, compareDate, timelineCategory, timelineCategory2, timelineCategory3])

    const fetchIncidents = async (orgId) => {

        if (categories.length === 0) {
            return
        }
        const records = await getFirebaseDocs(await getDocs(query(collection(db, 'incidents'), where('orgId', '==', orgId), where('incidentDate', '>=', startTime.toISOString()), where('incidentDate', '<=', endTime.toISOString()))))
        // records.sort((a, b) => a.created > b.created ? -1 : 1)

        let seriesLiquor = [], seriesGeneral = [], countGeneral = 0, countLiquor = 0

        for (let incident of records.filter(r => chosenVenues.value !== 'All' ? r.venue === chosenVenues.value : true)) {
            if (incident.type === types[0]) {
                countGeneral += 1

                if (incident.category) {
                    for (let category of incident.category) {
                        const categoryName = categories.find(r => r.id === category) && categories.find(r => r.id === category).name

                        const matchingSeries = seriesGeneral.find(r => r.name === categoryName)

                        const quantityToAdd = (parseInt(incident.quantity) || 1)

                        if (matchingSeries) {
                            seriesGeneral = seriesGeneral.map(r => r.name === categoryName ? { ...r, value: r.value + quantityToAdd } : r)
                        } else {
                            seriesGeneral.push({
                                name: categoryName, value: quantityToAdd
                            })
                        }
                    }
                }

            } else if (incident.type === types[1]) {
                countLiquor += 1

                for (let category of incident.category) {
                    const categoryName = categories.find(r => r.id === category) && categories.find(r => r.id === category).name

                    const matchingSeries = seriesLiquor.find(r => r.name === categoryName)

                    const quantityToAdd = (parseInt(incident.quantity) || 1)

                    if (matchingSeries) {
                        seriesLiquor = seriesLiquor.map(r => r.name === categoryName ? { ...r, value: r.value + quantityToAdd } : r)
                    } else {
                        seriesLiquor.push({
                            name: categoryName, value: quantityToAdd
                        })
                    }
                }
            }
        }

        updateSeriesWithZeroes(seriesLiquor, categories.filter(r => r.type === types[1]), setIncidents)
        updateSeriesWithZeroes(seriesGeneral, categories.filter(r => r.type === types[0]), setIncidentsGeneral)

    }

    const fetchPreviousCurrentDayIncidents = async (orgId) => {

        if (categories.length === 0) {
            return
        }

        const daysDifference = differenceInDays(new Date(), compareDate)
        const recordsPrevious = await getFirebaseDocs(await getDocs(query(collection(db, 'incidents'), where('type', '==', types[1]), where('orgId', '==', orgId), where('incidentDate', '>=', subDays(startTime, daysDifference).toISOString()), where('incidentDate', '<=', subDays(endTime, daysDifference).toISOString()))))
        const recordsCurrent = await getFirebaseDocs(await getDocs(query(collection(db, 'incidents'), where('type', '==', types[1]), where('orgId', '==', orgId), where('incidentDate', '>=', startTime.toISOString()), where('incidentDate', '<=', endTime.toISOString()))))
        setIncidentsPrev(computeHourlyChart(recordsPrevious.filter(r => timelineCategory ? r.category.includes(timelineCategory.value) : true)))
        setIncidentsCurrent(computeHourlyChart(recordsCurrent.filter(r => timelineCategory ? r.category.includes(timelineCategory.value) : true)))

        if (timelineCategory2) {
            setIncidentsPrev2(computeHourlyChart(recordsPrevious.filter(r => timelineCategory2 ? r.category.includes(timelineCategory2.value) : true)))
            setIncidentsCurrent2(computeHourlyChart(recordsCurrent.filter(r => timelineCategory2 ? r.category.includes(timelineCategory2.value) : true)))
        } else {
            setIncidentsPrev2(computeHourlyChart([]))
            setIncidentsCurrent2(computeHourlyChart([]))
        }

        if (timelineCategory3) {
            setIncidentsPrev3(computeHourlyChart(recordsPrevious.filter(r => timelineCategory3 ? r.category.includes(timelineCategory3.value) : true)))
            setIncidentsCurrent3(computeHourlyChart(recordsCurrent.filter(r => timelineCategory3 ? r.category.includes(timelineCategory3.value) : true)))
        } else {
            setIncidentsPrev3(computeHourlyChart([]))
            setIncidentsCurrent3(computeHourlyChart([]))
        }
        // records.sort((a, b) => a.created > b.created ? -1 : 1)
    }



    const computeHourlyChart = records => {
        let timeline = hours.map(r => 0)
        for (let incident of records.filter(r => chosenVenues.value !== 'All' ? r.venue === chosenVenues.value : true)) {
            if (incident.type === types[1]) {
                const hourIncident = format(new Date(incident.incidentDate), 'HH')
                console.log('hourIncident', hourIncident, incident.quantity)
                const matchingHourIndex = hours.map(r => r.split(':')[0]).findIndex(r => parseInt(r) >= parseInt(hourIncident))
                timeline = timeline.map((r, idx) => idx == matchingHourIndex ? r + (parseInt(incident.quantity) || 1) : r)
            }
        }

        // Compute sum till each hour? 

        let sum = 0, sumTimeline = []
        for (let row of timeline) {
            sum += row
            sumTimeline.push(sum)
        }
        return sumTimeline
    }


    const updateSeriesWithZeroes = (series, categories, setSeries) => {

        const allCategoryNames = categories.map(r => r.name)
        const seriesCategoryNames = series.map(r => r.name)

        for (let catName of allCategoryNames) {
            if (!seriesCategoryNames.includes(catName)) {
                series.push({ name: catName, value: 0 })
            }
        }
        setSeries(series.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))

    }


    const options = {
        chart: {
            id: 'apexchart-categories'
        },
        xaxis: {
            categories: incidents.map(r => r.name)
        },
        yaxis: {
            // stepSize: 1,
            tickAmount: 10
        },
        dataLabels: {
            offsetY: -25,
            style: {
                colors: ['#000'],
            },

        },
        plotOptions: {
            bar: {
                dataLabels: {
                    position: 'top',
                },
            }
        },
        // responsive: [
        //     {
        //     breakpoint: 480,
        //     options: {
        //       chart: {
        //         width: 350
        //       }
        //     }
        //   }
        // ]
    }

    const optionsLine = {
        chart: {
            id: 'apexchart-categories-line'
        },
        xaxis: {
            categories: hours,
            labels: { style: { fontSize: '14px' } }

        },
        yaxis: {
            // stepSize: 1,
            // tickAmount: 1
        },
        stroke: {
            // width: [5, 7, 5],
            // curve: 'straight',
            dashArray: [2, 0, 2, 0, 2, 0,]
        },

        colors: ['#00e396', '#00e396', '#169bff', '#169bff', '#FF9800', '#FF9800',]
    }


    const optionsGeneral = {
        chart: {
            id: 'apexchart-categories'
        },
        xaxis: {
            categories: incidentsGeneral.map(r => r.name),
            labels: { style: { fontSize: '14px' } }
        },
        yaxis: {
            // stepSize: 1,
            tickAmount: 10
        },
        dataLabels: {
            offsetY: -25,
            style: {
                colors: ['#000'],
            },

        },
        plotOptions: {
            bar: {
                dataLabels: {
                    position: 'top',
                },
            }
        },
        // responsive: [
        //     {
        //     breakpoint: 480,
        //     options: {
        //       chart: {
        //         width: 350
        //       }
        //     }
        //   }
        // ]
    }


    const seriesLiquorPrev = [{
        name: `previous-date-${timelineCategory ? timelineCategory.label : ''}`,
        data: incidentsPrev,
    }]

    const seriesLiquorCurrent = [{
        name: `current-day-${timelineCategory ? timelineCategory.label : ''}`,
        data: incidentsCurrent,
    }]

    const seriesLiquorPrev2 = [{
        name: `previous-date-${timelineCategory2 ? timelineCategory2.label : ''}`,
        data: incidentsPrev2,
    }]

    const seriesLiquorCurrent2 = [{
        name: `current-day-${timelineCategory2 ? timelineCategory2.label : ''}`,
        data: incidentsCurrent2,
    }]

    const seriesLiquorPrev3 = [{
        name: `previous-date-${timelineCategory3 ? timelineCategory3.label : ''}`,
        data: incidentsPrev3,
    }]

    const seriesLiquorCurrent3 = [{
        name: `current-day-${timelineCategory3 ? timelineCategory3.label : ''}`,
        data: incidentsCurrent3,
    }]


    const seriesLiquor = [{
        name: 'today',
        data: incidents.map(r => r.value)
    }]


    const seriesGeneral = [{
        name: 'series-categories-general',
        data: incidentsGeneral.map(r => r.value)
    }]

    return (<div>
        <Header />

        <div style={{ margin: '10px' }}>
            {errorMessage && errors.length > 0 && <p style={{ color: 'red', textAlign: 'center' }}>{errorMessage}</p>}



            <div style={{ display: 'flex', gap: '20px', width: '100%', marginLeft: 'auto', marginBottom: '20px', flexWrap: 'wrap', justifyContent: 'flex-end' }}>
                <div style={{ width: '30%', width: '300px', }}>
                    <Dropdown errorFields={errors} name={'venue'} label={'Venues'} options={[{ label: 'All', value: 'All' }, ...venues.map(r => ({ label: r.name, value: r.id }))]} value={chosenVenues} setValue={setChosenVenues} />
                </div>
                <div style={{ width: '20%', width: '160px', }}>
                    <Dropdown errorFields={errors} name={'report-date'} label={'Report Start Time'} options={startDateOptions.map(r => ({ label: r, value: r }))} value={startReportHour} setValue={setStartReportHour} />
                </div>
                <div style={{ width: '20%', width: '160px', }}>
                    <Dropdown errorFields={errors} name={'report-end-date'} label={'Report End Time'} options={startDateOptions.map(r => ({ label: r, value: r }))} value={endReportHour} setValue={setEndReportHour} />
                </div>
            </div>

            <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%', gap: '40px' }}>

                <div style={{ width: 'calc(49% - 20px)' }}>
                    <h3>Information</h3>
                    <table style={{ width: '100%' }}>
                        {/* <thead>
                        <tr>
                            <th>Title</th>
                            <th>Message</th>
                        </tr>
                    </thead> */}
                        <tbody>
                            {messages.map(r => <tr key={r.name}>
                                <td>{r.name}</td>
                                <td>{r.value}</td>
                            </tr>)}
                        </tbody>
                    </table>
                </div>

                <div style={{ width: 'calc(49% - 20px)' }}>
                    <h3>Liquor & Gaming</h3>
                    <Chart options={options} series={seriesLiquor} type="bar" width={'100%'} height={500} />
                </div>

                <div style={{ width: 'calc(49% - 20px)' }}>
                    <h3>General Log</h3>
                    <Chart options={optionsGeneral} series={seriesGeneral} type="bar" width={'100%'} height={500} />
                </div>

                <div style={{ width: 'calc(49% - 20px)' }}>
                    <h3>Liquor & Gaming Comparison</h3>
                    <div style={{ display: 'flex', gap: '20px' }}>
                        <div style={{ width: '200px' }}>
                            <Calendar showDateOnly startDate={compareDate} setStartDate={setCompareDate} label={'Compare Date'} />
                        </div>
                        <div style={{ width: '200px' }}>
                            <Dropdown errorFields={errors} name={'category-selection'} label={'Category Filter'} options={categories.filter(r => r.type === types[1]).sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase()).map(r => ({ label: r.name, value: r.id }))} value={timelineCategory} setValue={setTimelineCategory} />
                        </div>
                        <div style={{ width: '200px' }}>
                            <Dropdown errorFields={errors} name={'category-selection2'} label={'Category Filter'} options={categories.filter(r => r.type === types[1]).sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase()).map(r => ({ label: r.name, value: r.id }))} value={timelineCategory2} setValue={setTimelineCategory2} />
                        </div>
                        <div style={{ width: '200px' }}>
                            <Dropdown errorFields={errors} name={'category-selection3'} label={'Category Filter'} options={categories.filter(r => r.type === types[1]).sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase()).map(r => ({ label: r.name, value: r.id }))} value={timelineCategory3} setValue={setTimelineCategory3} />
                        </div>
                    </div>
                    <Chart options={optionsLine} series={[seriesLiquorPrev, seriesLiquorCurrent, seriesLiquorPrev2, seriesLiquorCurrent2, seriesLiquorPrev3, seriesLiquorCurrent3].flat()} type="line" width={'100%'} height={500} />
                </div>
            </div>
        </div>
    </div>)
}

export default Dashboard